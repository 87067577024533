.el-form-item {
  margin-bottom: 18px;
}
.czzjf {
  width: 150px;
  margin: auto;
}
.czzjf /deep/ .el-form-item__content {
  margin-left: 0 !important;
}
.fileNameUpload /deep/ .upload-btn {
  height: 40px !important;
}
.fileNameUpload /deep/ .upload-btn .el-upload {
  height: 40px !important;
  border: none !important;
}
.fileNameUpload /deep/ .el-form-item__content {
  display: flex;
}
.noticeContent /deep/ .el-textarea__inner {
  min-height: 200px !important;
  resize: none !important;
}
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.select-header /deep/ .el-input__validateIcon {
  display: none ;
}
